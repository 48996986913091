import React, { Component } from 'react';

import Layout from 'src/components/layout';
import Disclaimer from '../../components/disclaimer';
import ConsumerPanel from '../../components/consumer-panel';

import { AppStateContext } from 'src/contexts/app-state-provider.context';

import PersonalResourcesImg from '../../images/how-i-get-banner.jpeg';
import ReactGA from "react-ga4";
import { BannerComponent } from '../../components/banner-component';



import ConsultDoctor from '../../components/consult-doctor';

import './index.scss';

class PatientPage extends Component {
  static contextType = AppStateContext;
  state = {
    isAudioVisible: false
  };
  componentDidMount() {
    this.context.setSection('get-vabysmo');
    this.context.setPage('get-vabysmo');
  }

  trackDownloads(category,action){
    ReactGA.event({
      category,
      action
    });
  }

  render() {
    const title = {
      english: 'Get Vabysmo'
    };
    return (

     
      <Layout title={title} showNavbar={true} className="patient">
         <BannerComponent backgroundPhoto={PersonalResourcesImg} titleText="How can I get VABYSMO?"/>
        <section className='container get-vabysmo'>
          <div className='row'>
            <div className='col-md-12 col-xs-12 '>
            <h2 className='text-center'>What is the cost of VABYSMO?</h2>
               <p className='text-center'>  VABYSMO is not a PHARMAC funded medicine, which means
                  you will need to pay for it privately.</p> 

                 <div className='text-center d-flex justify-content-center align-items-center mx-auto p-5 mt-4 quote second-variation-quote'><h4> The price of VABYSMO is NZ$1,799.75
                  (inclusive of GST) per vial<sup>*</sup></h4></div>

                  <p className='justify-content'>  *This represents the price of VABYSMO only. Fees and charges associated with consultation,
                  reconstitution, administration, shipping and handling, and dispensing may apply.</p>
                  <p className=" justify-content">Roche also offers support through the <span className='hightligh-text'>VABYSMO Cost Share
                  Programme,</span> which can assist with the cost of your medicine.
                  To find out more, ask your ophthalmologist about the costs,
                  benefits and safety associated with VABYSMO.</p>
              </div>
              <div className='col-md-12 col-xs-12'>
                  <h2 className='second-heading'>Does my healthcare insurance cover VABYSMO in New Zealand?</h2>
                  <p className='last-paragraph'><span className='hightligh-text'>If you have health insurance, carefully check what is
                      covered under your policy</span> – every health insurance provider
                      has different benefits that cover various treatments and
                      procedures.</p> 

                  

            </div>
          </div>
         
        </section>
       
        <ConsultDoctor bgColor="blue"/>
        <Disclaimer />
        <ConsumerPanel />
      </Layout>
    );
  }
}

export default PatientPage;
